<template>
  <div>
<!--    <app-content/>-->
    <router-view/>
  </div>
</template>

<script>
import AppContent from './pages/layout/Content'

export default {
  components: {AppContent},
  computed: {
    isLoggedIn() {
      return this.$store.getters['profile/loggedIn'];
    },
  }
};
</script>
