import client from './client'

export default {

    async login(credentials) {
        return client.parseResponse(await client.post('/login', credentials))
    },
    async logout(filters) {
        return client.parseResponse(await client.get('/logout', filters))
    },

    async registerUser(data) {
        return client.parseResponse(await client.post('/register', data))
    },
    async verifyUser(data) {
        return client.parseResponse(await client.post('/verify-account', data))
    },
    async verifyUserOTP(data) {
        return client.parseResponse(await client.post('/verify-account-otp', data))
    },

    async sendPhone(data) {
        return client.parseResponse(await client.post('/forgot-password/', data))
    },
    async resetPassword(data) {
        return client.parseResponse(await client.post('/reset/' + data.id, data))
    },

    async reset(data) {
        return client.parseResponse(await client.post('/reset-password/', data))
    },

    async createUser(data) {
        return client.parseResponse(await client.post('/users', data))
    },
    async getUser(id) {
        return client.parseResponse(await client.get('/users/' + id));
    },

    async getUserPermissions(data) {
        return client.parseResponse(await client.post('/user-permissions'));
    },
}
