export default [
    {
        path: '/login',
        name: 'Log In',
        components: {
            // default: () => import(/* webpackChunkName: "auth" */ '../views/auth/login'),
        },
        meta: {
            guest: true,
            show_in_search: false
        },
    },
];
