import api from '../api'
import { ElMessage } from 'element-plus'

export default {
    namespaced: true,
    state: {
        errors: {
            data: {
                errors: {
                    full_name: [],
                    phone_number: [],
                    email: [],
                    message: [],
                    captcha: [],
                    ticket_category_id: []
                }
            }
        },
        has_errors: false,
        loading: false,
        input_request: null,
    },
    getters: {
        errors: state => state.errors,
        has_errors: state => state.has_errors,
        loading: state => state.loading,
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            full_name: [],
                            phone_number: [],
                            email: [],
                            message: [],
                            captcha: [],
                            ticket_category_id: []
                        }
                    }
                };
            }
        }
    },
    actions: {

        async sendMessage({dispatch, commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.sendMessage(data);
                commit('setLoading', false)
                ElMessage.success({
                    message: 'Your message has been received.' +
                        'Our team is reviewing it and you will receive a reply soon.',
                    duration: 10000
                });
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage.error('Correct the errors below');
            }
        },


    }
}
