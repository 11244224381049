<template>
  <section class="newsletter-section section-b-space">
    <div class="container-fluid-lg">
      <div class="newsletter-box newsletter-box-2">
        <div class="newsletter-contain py-5">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xxl-4 col-lg-5 col-md-7 col-sm-9 offset-xxl-2 offset-md-1">
                <div class="newsletter-detail">
                  <h2>Join Sokoni's newsletter and get...</h2>
                  <h5>KES 100 discount for your first order</h5>
                  <div class="input-box">
                    <input type="email" class="form-control" id="exampleFormControlInput1"
                           placeholder="Enter Your Email" v-model="form.email">
                    <i class="fa-solid fa-envelope arrow"></i>
                    <button class="sub-btn  btn-animation" @click="subscribe" v-loading="loading">
                      <span class="d-sm-block d-none">Subscribe</span>
                      <i class="fa-solid fa-arrow-right icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "Newsletter",
  data() {
    return {
      form: {}
    }
  },
  computed: {
    ...mapGetters({
      'loading': 'profile/loading'
    })
  },
  methods: {
    ...mapActions({
      'subscribeNewletter': 'profile/subscribeNewsletter'
    }),
    subscribe() {
      this.subscribeNewletter(this.form)
    }
  }
}
</script>
