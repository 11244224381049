<template>
  <footer class="section-t-space">
    <div class="container-fluid-lg">
      <div class="service-section">
        <div class="row g-3">
          <div class="col-12">
            <div class="service-contain">
              <div class="service-box">
                <div class="service-image">
                  <img src="/assets/svg/product.svg" class="blur-up lazyload" alt="">
                </div>

                <div class="service-detail">
                  <h5>
                    Post your items for sale
                  </h5>
                </div>
              </div>

              <div class="service-box">
                <div class="service-image">
                  <img src="/assets/svg/delivery.svg" class="blur-up lazyload" alt="">
                </div>

                <div class="service-detail">
                  <h5>Deliver your items to clients</h5>
                </div>
              </div>

              <div class="service-box">
                <div class="service-image">
                  <img src="/assets/svg/market.svg" class="blur-up lazyload" alt="">
                </div>

                <div class="service-detail">
                  <h5>Get Best Value for money</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main-footer section-b-space section-t-space">
        <div class="row g-md-4 g-3">
          <div class="col-xl-3 col-lg-4 col-sm-6">
            <div class="footer-logo">
              <div class="theme-logo">
                <a href="/">
                  <img src="/assets/images/logo/sokoni.webp" class="blur-up lazyload" alt="">
                </a>
              </div>

              <div class="footer-logo-contain">
                <p>We are your fastest and easiest way to sell in Kenya!</p>

                <ul class="address">
                  <li>
                    <i data-feather="home"></i>
                    <a href="javascript:void(0)">
                      Nairobi Kenya, Westlands
                    </a>
                  </li>
                  <li>
                    <i data-feather="mail"></i>
                    <a href="javascript:void(0)">info@sokoni.ke</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-xl col-lg-2 col-sm-3">
            <div class="footer-title">
              <h4>Useful Links</h4>
            </div>

            <div class="footer-contain">
              <ul>
                <li>
                  <a href="/" class="text-content">Home</a>
                </li>
                <li>
                  <a href="/auth" class="text-content">Start Selling</a>
                </li>
                <li>
                  <a href="/about" class="text-content">About Us</a>
                </li>
                <li>
                  <a href="/contact" class="text-content">Contact Us</a>
                </li>
                <li>
                  <a href="/faq" class="text-content">FAQs</a>
                </li>
                <li>
                  <a href="https://www.the-star.co.ke/classifieds/"
                     target="_blank"
                     class="text-content">Partners - Star Classifieds</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-xl-2 col-sm-3">
            <div class="footer-title">
              <h4>Help Center</h4>
            </div>

            <div class="footer-contain">
              <ul>
                <li>
                  <a href="/" class="text-content">Home</a>
                </li>
                <li>
                  <a href="/auth" class="text-content">Your Account</a>
                </li>
                <li>
                  <a href="/auth" class="text-content">Start Selling</a>
                </li>
                <li>
                  <a href="/search" class="text-content">Search</a>
                </li>
                <li>
                  <a href="/faq" class="text-content">FAQs</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-sm-6">
            <div class="footer-title">
              <h4>Contact Us</h4>
            </div>

            <div class="footer-contact">
              <ul>
                <li>
                  <div class="footer-number">
                    <i data-feather="phone"></i>
                    <div class="contact-number">
                      <h6 class="text-content">Hotline 24/7 :</h6>
                      <h5>+254 772 591 591</h5>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="footer-number">
                    <i data-feather="mail"></i>
                    <div class="contact-number">
                      <h6 class="text-content">Email Address :</h6>
                      <h5>info@sokoni.ke</h5>
                    </div>
                  </div>
                </li>

                <li class="social-app">
                  <h5 class="mb-2 text-content">
                    <a href="https://bit.ly/48QxHLx"
                       class="btn btn-sm btn-outline-info w-100 fa-border text-white btn-primary"
                       style="text-decoration: none;border: thin solid white; border-radius: 4px; background: lightseagreen"
                       target="_blank">
                      <span class="fa-brands fa-google-play"></span> &nbsp;Download Sokoni App
                    </a>
                  </h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="sub-footer section-small-space">
        <div class="reserve">
          <h6 class="text-content">{{ new Date().getFullYear() }} Sokoni All rights reserved</h6>
        </div>

        <div class="payment"></div>

        <div class="social-link">
          <h6 class="text-content">Stay connected :</h6>
          <ul>
            <li>
              <a href="https://www.facebook.com/sokonikenya.ke" target="_blank">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/" target="_blank">
                <i class="fa-brands fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/sokonike.ke" target="_blank">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://in.pinterest.com/" target="_blank">
                <i class="fa-brands fa-pinterest-p"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>
