<template>
  <div id="main" style="opacity: 1;">
    <!-- header -->
    <app-header />

    <MobileFixedMenu />

    <!-- Product Section Start -->
    <HomeProductsComponent />

    <div class="bg-overlay"></div>


  </div>
</template>
<script>
import AppHeader from '../../components/Sections/Header'
import FooterComponent from '@/components/Sections/Footer'
import MobileFixedMenu from '@/components/Shared/MobileFixedMenu.vue'
import Newsletter from '@/components/Shared/Newsletter.vue'
import HomeProductsComponent from '@/components/Sections/HomeProductsComponent.vue'

export default {
  components: {
    HomeProductsComponent,
    Newsletter,
    MobileFixedMenu,
    FooterComponent,
    AppHeader
  },
  data() {
    return {
      filters: {},
      active_index: -1
    }
  }
}
</script>
