<template>
  <section class="category-section-3">
    <div class="container-fluid-lg">
      <h2 style="font-size: 18px">
        Post free classifieds ads in Kenya at Sokoni.ke. Get a free business website!
      </h2>
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-row" style="overflow-y: hidden; overflow-x: scroll;  scrollbar-width: none;">
            <div class="category-box-list" style="margin-right: 10px; min-width: 170px;" v-if="categories"
                 v-loading="loadingCategories"
                 v-for="category in categories">

              <router-link :to="''+category?.link"
                           class="category-name"
                           :title="category?.page_title">
                <h4>{{ category?.name }}</h4>
              </router-link>
              <div class="category-box-view">
                <router-link :to="''+category?.link"
                             tabindex="-1">
                  <img :src="category?.image_url"
                       class="img-fluid lazyload" :alt="category?.page_title">
                </router-link>
                <router-link :to="''+category?.link"
                             class="btn shop-button" tabindex="-1">
                  <span>Find Deals</span>
                  <i class="fas fa-angle-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="product-section">
    <div class="container-fluid-lg">
      <div class="row g-sm-4 g-3">
        <div class="col-xxl-3 col-xl-3 d-none d-xl-block">
          <div class="p-sticky">
            <div class="category-menu">
              <h3>Category</h3>
              <ul>
                <li v-for="category in categories">
                  <div class="category-list">
                    <img :src="category?.image_url" class="lazyload"
                         :alt="category?.page_title">
                    <h5>
                      <router-link :to="''+category?.link"
                                   :title="category?.page_title">
                        {{ category?.name }}
                      </router-link>
                    </h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xxl-9 col-xl-9">
          <div class="title title-flex">
            <div>
              <h2>Trending Ads Today</h2>
            </div>
          </div>

          <div class="section-b-space">
            <div
              v-masonry="masonry" item-selector=".cols" transition-duration="0"
              class="row g-sm-4 g-3 row-cols-xxl-4 row-cols-xl-4 row-cols-lg-2 row-cols-md-4 row-cols-2 product-list-section masonry-container"
              id="masonry">
              <div v-for="listing in listings" class="cols" v-masonry-tile>
                <div
                  :class="listing?.package?.amount> 0 ? 'product-box-3 boosted wow fadeInUp  ':'product-box-3 wow fadeInUp '"
                  :style="listing?.package?.amount> 0? 'border:2px solid rgb(254, 160, 60)':''"
                  :data-label="listing?.package?.amount>0?listing?.package?.name:''">
                  <div class="product-header">
                    <div class="product-image">
                                    <span class="ad-total-images" :title="listing?.images?.length + 'images'">
                                      {{ listing?.images?.length }}
                                    </span>
                      <router-link
                        :title="listing?.category?.page_title"
                        :to="listing?.link">
                        <img :src="listing?.images?.[0]?.image_url"
                             class="img-fluid lazyload" :alt="listing?.name + ' image sokoni'">
                      </router-link>
                    </div>
                  </div>
                  <div class="product-footer">
                    <div class="product-detail">
                      <span class="span-name">
                          {{ listing?.category?.name }}
                      </span>
                      <router-link
                        :title="listing?.name + ' - ' + listing?.category?.name + ' - Free Classifieds in Kenya'"
                        :to="listing?.link">
                        <h2 class="name" style="font-size: 16px">
                          {{ listing?.name + ' - ' + listing?.category?.name + ' - Free Classifieds in Kenya' }}
                        </h2>
                      </router-link>
                      <p class="text-content mt-1 mb-2 product-content">
                        {{ listing?.description }}
                      </p>
                      <h6 class="unit">
                        {{ listing?.location?.name }}
                      </h6>
                      <h5 class="price">
                        <span class="theme-color">
                            {{ (listing?.currency_price) }}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="section-t-space section-b-space d-none">
            <div class="row g-md-4 g-3">
              <div class="col-xxl-8 col-xl-12 col-md-7">
                <div class="banner-contain hover-effect">
                  <div class="banner-details p-center-left p-4">
                    <div>
                      <h2 class="text-kaushan fw-normal theme-color">Start Selling</h2>
                      <h3 class="mt-2 mb-3">TAKE ON THE DAY!</h3>
                      <p class="text-content banner-text">
                        We empower sellers and buyers by providing a seamless platform to
                        conduct business.
                      </p>
                      <router-link
                        title="Add Listing"
                        to="/add-listing"
                        class="btn btn-animation btn-sm mend-auto">
                        Star Selling
                        <i class="fa-solid fa-arrow-right icon"></i></router-link>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xxl-4 col-xl-12 col-md-5">
                <router-link
                  title="Login"
                  to="/auth" class="banner-contain hover-effect h-100">
                  <div class="banner-details p-center-left p-4 h-100">
                    <div>
                      <h2 class="text-kaushan fw-normal text-warning">Sokoni!</h2>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import GeneralController from '@/mixins/GeneralController'
import { Loading } from 'element-plus/es/components/loading/src/service'

export default {
  name: 'HomeProductsComponent',
  data() {
    return {
      filters: {
        search: '',
        paginate: true,
        per_page: 12,
        page: 1
      },
      listing: {}
    }
  },
  components: { Loading },
  mixins: [GeneralController],
  methods: {
    ...mapActions({
      'getCategories': 'categories/getCategories',
      'getListings': 'listings/getListings',
      'getListingsScroll': 'listings/getListingsScroll'
    })
  },
  computed: {
    ...mapGetters({
      'categories': 'categories/categories',
      'listings': 'listings/listings',
      'loadingCategories': 'categories/loading',
      'loading': 'listings/loading',
      'last_page': 'listings/last_page'
    })
  },
  created() {
    this.getCategories({})
    this.getListings({
      ...this.filters,
      reset: true
    })
  },
  mounted() {
    this.resetMetaTags()
    document.title = "Free classifieds in Kenya 🛍️ Post free classified ads."
    window.onscroll = () => {
      if (this.$route.path === '/') {
        let bottomOfWindow = (document.documentElement.scrollHeight - document.documentElement.scrollTop) - document.documentElement.clientHeight <= 1500
        if (!this.loading && Number(this.filters.page) + 1 <= Number(this.last_page)) {
          if (bottomOfWindow) {
            this.filters.page += 1
            this.getListingsScroll(this.filters)
          }
        }
      }
    }
  }
}
</script>
<style>
.boosted {
  background: #e3e4f6;
}

.boosted::before {
  position: absolute;
  content: '';
  background: #a86c24;
  height: 18px;
  width: 18px;
  /* Added lines */
  top: 1.2rem;
  right: -0.62rem;
  transform: rotate(45deg);
  z-index: -1 !important;

}

.boosted::after {
  font-weight: bold;
  position: absolute;
  content: attr(data-label);
  top: 4px;
  right: -14px;
  padding: 0.1rem;
  width: auto;
  min-width: 5rem;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  background: #fcaa3f;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
}

.masonry-item {
  //transition: all 0.3s ease;
}


</style>
