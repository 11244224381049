import client from './client'

export default {
    async getPackages(filters){
        return client.parseResponse(await client.get('/packages', filters));
    },

    async getBusinessPackages(filters){
        return client.parseResponse(await client.get('/business-packages', filters));
    },
}
