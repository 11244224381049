import api from '../api'
import { ElMessage } from 'element-plus'

export default {
  namespaced: true,
  state: {
    counties: { data: [], current_page: 1, total: 0, per_page: 10 },
    sub_counties: { data: [], current_page: 1, total: 0, per_page: 10 },
    errors: {
      data: {
        errors: {
          name: []
        }
      }
    },
    has_errors: false,
    loading: false
  },
  getters: {
    counties: state => state.counties,
    sub_counties: state => state.sub_counties,
    errors: state => state.errors,
    has_errors: state => state.has_errors,
    loading: state => state.loading
  },
  mutations: {
    setCounties(state, data) {
      state.counties = data
    },
    setSubCounties(state, data) {
      state.sub_counties = data
    },
  },
  actions: {
    async getCounties({ commit }, filters) {
      try {
        commit('activateLoading', 'user/list', { root: true })
        const response = await api.getCounties(filters)
        commit('setCounties', response)
        commit('deactivateLoading', 'county/list', { root: true })
      } catch (e) {
        ElMessage.error(e.response.data.message)
      }
    },

    async getSubCounties({ commit }, filters) {
      try {
        commit('activateLoading', 'user/list', { root: true })
        const response = await api.getSubCounties(filters)
        commit('setSubCounties', response)
        commit('deactivateLoading', 'county/list', { root: true })
      } catch (e) {
        ElMessage.error(e.response.data.message || 'Unable to get approximate locations')
      }
    }
  }
}
