import users from './users.api'
import categories from './categories.api'
import counties from './counties.api'
import profile from './profile.api'
import listings from './listings.api'
import contacts from './contacts.api'
import packages from './packages.api'
import payments from './payments.api'
import businesses from './businesses.api'

export default {
    ...users,
    ...counties,
    ...profile,
    ...listings,
    ...categories,
    ...contacts,
    ...packages,
    ...payments,
    ...businesses
}
