import client from './client'

export default {
  async getCounties(filters) {
    return client.parseResponse(await client.get('/counties', filters))
  },

  async getSubCounties(filters) {
    return client.parseResponse(await client.get('/sub-counties', filters))
  }
}
