import client from './client'

export default {
    async login(credentials) {
        return client.parseResponse(await client.post('/login', credentials))
    },
    async validateGoogleLogin(credentials) {
        return client.parseResponse(await client.post('/validate-google-login', credentials))
    },
    async getProfile(filters) {
        return client.parseResponse(await client.get('/profile', filters))
    },
    async logout(filters) {
        return client.parseResponse(await client.post('/logout', filters))
    },

    async createUser(data) {
        return client.parseResponse(await client.post('/users', data));
    },
    async subscribeNewsletter(data) {
        return client.parseResponse(await client.post('/newsletter', data));
    },
    async updateProfile(data) {
        return client.parseResponse(await client.post('/update-profile/' + data.id, data));
    },

    async getUser(id) {
        return client.parseResponse(await client.get('/users/' + id));
    },
}
