import { createStore } from 'vuex'
import users from './users.store'
import profile from './profile.store'
import counties from './counties.store'
import categories from './categories.store'
import listings from './listings.store'
import contacts from './contacts.store'
import packages from './packages.store'
import payments from './payments.store'
import businesses from './businesses.store'

export default new createStore({
  state: {
    autoSave: true,
    loading: {},
    clear_form: false
  },
  getters: {
    clear_form: state => state.clear_form
  },
  mutations: {
    activateLoading(state, key) {
      state.loading = true
    },
    deactivateLoading(state, key) {
      state.loading = false
    },
    setAutoSave(state, autoSave) {
      state.autoSave = autoSave
    },
    setClearForm(state, val) {
      state.clear_form = val
    }
  },
  modules: {
    users,
    profile,
    counties,
    categories,
    listings,
    contacts,
    packages,
    payments,
    businesses
  }
})
