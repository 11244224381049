import api from '../api'
import { ElMessage } from 'element-plus'

export default {
  namespaced: true,
  state: {
    loading: false,
    users: { data: [], current_page: 1, total: 0, per_page: 10 },
    user_roles: { data: [], current_page: 1, total: 0, per_page: 10 },
    user_permissions: { data: [] },
    user: null,
    authorization_error: null,
    errors: {
      data: {
        errors: {
          first_name: [],
          last_name: [],
          email: [],
          login: [],
          password: [],
          status: [],
          captcha: [],
          gender_id: [],
          otp: []
        }
      }
    },
    has_errors: false,
    authenticating: false,
    dialogVisible: false

  },
  getters: {
    dialogVisible: state => state.dialogVisible,
    user: state => state.user === null ? JSON.parse(localStorage.getItem('user')) : state.user,
    errors: state => state.errors,
    has_errors: state => state.has_errors,
    users: state => state.users,
    user_permissions: state => state.user_permissions,
    loading: state => state.loading
  },
  mutations: {

    setLoading(state, val) {
      state.loading = val
    },

    setDialogVisible(state, status) {
      state.dialogVisible = status
    },
    authenticating(state, val) {
      state.authenticating = val
    },
    setUserPermissions(state, data) {
      state.user_permissions = data
    },
    setUser(state, user) {
      state.user = user
    },
    setErrors(state, errors) {
      state.errors = errors
    },
    hasErrors(state, val) {
      state.has_errors = val
      if (!val) {
        // no errors
        state.errors = {
          data: {
            errors: {
              full_name: [],
              first_name: [],
              last_name: [],
              email: [],
              login: [],
              password: [],
              status: [],
              captcha: [],
              gender_id: [],
              otp: []
            }
          }
        }
      }
    }
  },
  actions: {
    async logout(context, filters = {}) {
      await api.logout(filters)
      localStorage.removeItem('app_token')
      localStorage.removeItem('user_id')
      localStorage.removeItem('user')
      localStorage.removeItem('applicant')
      window.location.href = '/login'
    },
    async registerUser({ dispatch, commit }, data) {
      try {
        commit('setLoading', true)
        commit('authenticating', true)
        commit('hasErrors', false)
        const response = await api.registerUser(data)
        commit('authenticating', false)
        await localStorage.setItem('user_id', response?.user?.id)
        await localStorage.setItem('user', JSON.stringify(response.user))
        let source_utm = localStorage.getItem('source_utm')
        if (source_utm !== null && source_utm !== undefined) {
          localStorage.removeItem('source_utm')
        }
        window.location.href = '/verify-account'
        ElMessage.success({
          message: 'Your account has been created successfully.'
        })
        commit('setLoading', false)
      } catch (e) {
        commit('authenticating', false)
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: 'There was a problem creating your account. Please try again later.'
        })
        commit('setLoading', false)
      }
    },

    async verifyUser({ dispatch, commit }, data) {
      try {
        commit('setLoading', true)
        commit('hasErrors', false)
        const response = await api.verifyUser(data)
        commit('setLoading', false)
        await localStorage.setItem('app_token', response.token)
        ElMessage.success({
          message: 'Your account has been verified successfully.'
        })
        commit('setLoading', false)
        window.location.href = '/dashboard'
      } catch (e) {
        commit('setLoading', false)
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: 'There was a problem verifying your account.'
        })
        commit('setLoading', false)
      }
    },

    async verifyUserOTP({ dispatch, commit }, data) {
      try {
        commit('setLoading', true)
        commit('hasErrors', false)
        const response = await api.verifyUserOTP(data)
        commit('setLoading', false)
        await localStorage.setItem('app_token', response.token)
        ElMessage.success({
          message: 'Your account has been verified successfully.'
        })
        commit('setLoading', false)
        window.location.href = '/dashboard'
      } catch (e) {
        commit('setLoading', false)
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: 'There was a problem verifying your account.'
        })
        commit('setLoading', false)
      }
    },
    async sendPhone({ dispatch, commit }, data) {
      try {
        commit('authenticating', true)
        commit('setLoading', true)
        commit('hasErrors', false)
        const response = await api.sendPhone(data)
        commit('authenticating', false)
        await localStorage.setItem('user_id', response.user.phone)
        commit('setLoading', false)
        ElMessage.success({
          message: 'Verification code sent successfully!',
          duration: 10000
        })
      } catch (e) {
        commit('setLoading', false)
        commit('authenticating', false)
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: 'Please correct the Errors below',
          duration: 10000
        })
      }
    },
    async resetPassword({ dispatch, commit }, data) {
      try {
        commit('authenticating', true)
        commit('hasErrors', false)
        const response = await api.resetPassword(data)
        commit('authenticating', false)
        await localStorage.setItem('app_token', response.token)
        await localStorage.setItem('user', JSON.stringify(response.user))
        window.location.href = '/dashboard'
        ElMessage.success({
          message: 'Password Reset Successfully!',
          duration: 10000
        })
      } catch (e) {
        commit('authenticating', false)
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: 'Please correct the Errors below',
          duration: 10000
        })
      }
    },
    async reset({ dispatch, commit }, data) {
      try {
        commit('authenticating', true)
        commit('hasErrors', false)
        const response = await api.reset(data)
        commit('authenticating', false)
        await localStorage.setItem('app_token', response.token)
        await localStorage.setItem('user', JSON.stringify(response.user))
        window.location.href = '/dashboard'
        ElMessage.success({
          message: 'Password Reset Successfully!',
          duration: 10000
        })
      } catch (e) {
        commit('authenticating', false)
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: 'Please correct the Errors below',
          duration: 10000
        })
      }
    },
    async createUser({ dispatch, commit }, data) {
      try {
        commit('setLoading', true)
        commit('hasErrors', false)
        await api.createUser(data)
        commit('setDialogVisible', false)
        ElMessage.success({
          message: 'User created successfully!'
        })
        commit('setLoading', false)
      } catch (e) {
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: 'There was a problem adding the user. Please t'
        })
        commit('setLoading', false)
      }
    },
    async getUser({ commit }, id) {
      try {
        const response = await api.getUser(id)
        commit('setUser', response?.data)
      } catch (e) {
        ElMessage.error(e.response.data.message)
      }
    },
    async getUserPermissions({ commit }, filters) {
      try {
        commit('activateLoading', 'role/list', { root: true })
        const response = await api.getUserPermissions(filters)
        commit('setUserPermissions', response?.data)
        commit('deactivateLoading', 'role/list', { root: true })
      } catch (e) {
        commit('deactivateLoading', 'role/list', { root: true })
        ElMessage.error(e.response.data.message)
      }
    }
  }
}
