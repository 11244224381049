export default [
    {
        path: '/users',
        name: 'System Users',
        components: {
            // default: () => import(/* webpackChunkName: "auth" */ '../views/profile/user'),
        },
        meta: {
            auth: true,
            parent_key: 'view_settings_nav',
            key: 'view_users',
            show_in_search: true
        },
    },
    {
        path: '/users/create/:id?',
        name: 'users-create',
        components: {
            // default: () => import('../views/profile/UserCreate.vue')
        },
        meta: {
            auth: true,
            parent_key: 'view_settings_nav',
            key: 'view_create_users',
            show_in_search: false
        }
    },
    {
        path: '/access-denied',
        name: 'access-denied',
        components: {
            // default: () => import(/* webpackChunkName: "auth" */ '../views/errors/access-denied'),
        },
        meta: {
            show_in_search: false
        }
    }
];
