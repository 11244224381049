export default [
  {
    path: '/',
    name: 'Home',
    components: {
      default: () => import('../pages/layout/Content.vue')
    },
    meta: {
      // guest: true,
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_users',
      show_in_search: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      default: () => import('../pages/Dashboard/Dashboard.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/business-setup',
    name: 'Business',
    components: {
      default: () => import('../pages/Dashboard/Business.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/dashboard/business/profile',
    name: 'Business Profile',
    components: {
      default: () => import('../pages/Dashboard/BusinessProfile.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/business/pay/:business_id/:package_id?',
    name: 'Business Payment',
    components: {
      default: () => import('../pages/Dashboard/BusinessPayment.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/shop/:shop_id',
    name: 'Shop Details',
    components: {
      default: () => import('../pages/Dashboard/ShopDetail.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    components: {
      default: () => import('../pages/Dashboard/Profile.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/profile/edit',
    name: 'Profile Edit',
    components: {
      default: () => import('../pages/Dashboard/ProfileEdit.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/listings/:category/:slug/:id',
    name: 'Listing Detail',
    components: {
      default: () => import(/* webpackChunkName: "auth" */ '../pages/layout/ListingDetail.vue')
    },
    meta: {
      show_in_search: false
    }
  },
  {
    path: '/listings/categories/:slug/:category_id/:sub_category_slug?/:sub_category_id?',
    name: 'Listing Category',
    components: {
      default: () => import(/* webpackChunkName: "auth" */ '../pages/ListingCategories.vue')
    },
    meta: {
      show_in_search: false
    }
  },

  {
    path: '/auth',
    name: 'Login',
    components: {
      default: () => import('../pages/Account/Auth')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/reset',
    name: 'Reset Code',
    components: {
      default: () => import('../pages/Account/ResetCode.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/reset-account',
    name: 'Reset Password',
    components: {
      default: () => import('../pages/Account/ResetPassword.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/register',
    name: 'Create Account',
    components: {
      default: () => import('../pages/Account/Register.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/verify-account',
    name: 'Verify Account',
    components: {
      default: () => import('../pages/Account/VerifyAccount')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/add-listing/:listing_id?',
    name: 'Add Listing',
    components: {
      default: () => import('../pages/Dashboard/AddListing.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/dashboard/business/add-catalogue/:listing_id?',
    name: 'Add Catalogue',
    components: {
      default: () => import('../pages/Dashboard/AddCatalogue.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/business/catalogue',
    name: 'Business Listing',
    components: {
      default: () => import('../pages/Dashboard/BusinessCatalogue.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/business/dashboard',
    name: 'Business Dashboard',
    components: {
      default: () => import('../pages/Dashboard/BusinessDashboard.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/boost-listing/:listing_id/:package_id?',
    name: 'Boost Listing',
    components: {
      default: () => import('../pages/Dashboard/BoostListing.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },

  {
    path: '/my-listings',
    name: 'My Listings',
    components: {
      default: () => import('../pages/Dashboard/Listings.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/search',
    name: 'Search Listings',
    components: {
      default: () => import('../pages/Search.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },

  {
    path: '/categories',
    name: 'Categories',
    components: {
      default: () => import('../pages/Categories.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/contact',
    name: 'Contact Us',
    components: {
      default: () => import('../pages/Contact')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    components: {
      default: () => import('../pages/Faq.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/about',
    name: 'About Us',
    components: {
      default: () => import('../pages/About')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/terms',
    name: 'Terms and Conditions',
    components: {
      default: () => import('../pages/Terms.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    components: {
      default: () => import('../pages/PrivacyPolicy.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  },
  {
    path: '/blog/best-classifieds-in-kenya',
    name: 'Best Classifieds in Kenya',
    components: {
      default: () => import('../pages/BestClassifieds.vue')
    },
    meta: {
      // auth: false,
      parent_key: 'view_settings_nav',
      key: 'view_create_users',
      show_in_search: false
    }
  }
  ,

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../pages/NotFound.vue')
  }

]
