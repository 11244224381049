import api from '../api'
import router from '../../routes'
import { ElMessage } from 'element-plus'

export default {
  namespaced: true,
  state: {
    loading: false,
    error: '',
    user_activity_logs: {},
    user: null,
    authenticated: false,
    users: { data: [], current_page: 1, total: 0, per_page: 50 },
    users_all: [],
    user_products: {},
    excel: false,
    has_errors: false,
    edit_user: {},
    login_modal_visible: false,
    errors: {
      data: {
        errors: {
          name: [],
          email: [],
          phone: [],
          user_type_id: [],
          role_group_id: [],
          user_team_id: [],
          user_channel_id: [],
          country_id: [],
          region_id: [],
          price_list_id: []
        }
      }
    },
    user_teams: [],
    user_suppliers: [],
    user_companies: [],
    supervisors: []
  },
  getters: {
    loggedIn() {
      return !!window.localStorage.getItem('app_token')
    },
    user: state => state.user,
    authenticated: state => state.authenticated,
    users: state => state.users,
    has_errors: state => state.has_errors,
    errors: state => state.errors,
    error: state => state.error,
    loading: state => state.loading,
    login_modal_visible: state => state.login_modal_visible
  },
  mutations: {
    setLoginModalVisible(state, val) {
      state.login_modal_visible = val
    },
    hasErrors(state, errors) {
      state.errors = errors
    },
    setErrors(state, errors) {
      state.errors = errors
      if (!errors) {
        state.errors = {
          name: [],
          email: [],
          phone: [],
          user_type_id: [],
          role_group_id: [],
          user_team_id: [],
          user_channel_id: [],
          country_id: [],
          region_id: [],
          price_list_id: []
        }

      }
    },
    setUser(state, user) {
      state.user = user
    },
    setAuthenticated(state, val) {
      state.authenticated = val
    },
    setLoading(state, val) {
      state.loading = val
    },
    setEditUser(state, user) {
      state.edit_user = user
    }
  },
  actions: {
    async authenticate({ commit }, credentials) {
      try {
        commit('setLoading', true)
        const response = await api.login(credentials)
        await localStorage.setItem('app_token', response?.user?.token)
        await localStorage.setItem('user', JSON.stringify(response?.user))
        commit('setUser', (response?.user))
        commit('setAuthenticated', true)
        ElMessage.success({
          message: 'Successfully Logged In!',
          offset: 300
        })
        commit('setLoading', false)
      } catch (e) {
        ElMessage.error({
          message: 'The credentials provided are incorrect.',
          offset: 300
        })
        commit('setLoading', false)
        commit('hasErrors', true)
        commit('setErrors', e.response)
      }
    },

    async validateGoogleLogin({ commit }, credentials) {
      try {
        commit('setLoading', true)
        const response = await api.validateGoogleLogin(credentials)
        await localStorage.setItem('app_token', response?.user?.token)
        await localStorage.setItem('user', JSON.stringify(response?.user))
        commit('setUser', (response?.user))
        ElMessage.success({
          message: 'Successfully Logged In!',
          offset: 300
        })
        commit('setLoading', false)
      } catch (e) {
        ElMessage.error({
          message: 'The credentials provided are incorrect.',
          offset: 300
        })
        commit('setLoading', false)
        commit('hasErrors', true)
        commit('setErrors', e.response)
      }
    },
    async getProfile({ commit }, filters = {}) {
      try {
        const response = await api.getProfile(filters)
        commit('setUser', response?.data)
      } catch (e) {
        await api.logout(filters)
        localStorage.removeItem('app_token')
        window.location.href = '/auth'
      }
    },
    async logout(context, filters = {}) {
      await api.logout(filters)
      localStorage.removeItem('app_token')
      window.location.href = '/'
    },

    async getUser({ commit }, filters) {
      try {
        commit('activateLoading', 'users/list', { root: true })
        const response = await api.getUser(filters)
        commit('setEditUser', response.data)
        commit('deactivateLoading', 'users/list', { root: true })
      } catch (e) {
        commit('activateLoading', 'users/list', { root: true })
        ElMessage.error('Error fetching user!')
      }
    },
    async createUser({ commit }, data) {
      try {
        await api.createUser(data)
        ElMessage.success('User added successfully!')
        commit('hasErrors', false)
        await router.replace('/users')
      } catch (e) {
        ElMessage.error(e.response.data.message)
        commit('hasErrors', true)
        commit('setErrors', e.response)
      }
    },
    async subscribeNewsletter({ commit }, data) {
      try {
        commit('setLoading', true)
        await api.subscribeNewsletter(data)
        ElMessage.success('Subscription added successfully!')
        commit('setLoading', false)
        commit('hasErrors', false)
      } catch (e) {
        commit('setLoading', false)
        ElMessage.error(e.response.data.message)
        commit('hasErrors', true)
        commit('setErrors', e.response)
      }
    },
    async updateProfile({ commit }, data) {
      commit('setLoading', true)
      try {
        await api.updateProfile(data)
        await router.replace('/dashboard')
        ElMessage.success('Profile updated successfully!')
        commit('hasErrors', false)
        commit('setLoading', false)
      } catch (e) {
        commit('setLoading', false)
        ElMessage.error(e.response.data.message)
        commit('hasErrors', true)
        commit('setErrors', e.response)
      }
    }

  }
}
