<template>
  <div>
    <header class="pb-md-4 pb-0">
      <div class="header-top">
        <div class="container-fluid-lg">
          <div class="row">
            <div class="col-xxl-3 d-xxl-block d-none">
              <div class="top-left-header">
                <i class="iconly-Location icli text-white"></i>
                <span class="text-white">
                  Karibu Sokoni
                </span>
              </div>
            </div>
            <div class="col-xxl-6 col-lg-9 d-lg-block">
              <div class="header-offer">
                <div class="notification-slider">
                  <div>
                    <div class="timer-notification">
                      <h1 class="d-block" style="font-size: 14px; font-weight: unset; text-transform: unset">
                        Post free classifieds ads in kenya. The fastest way to buy and sell online in Kenya.
                        Get Free website page for your business at Sokoni! Browse cars, houses for rent, property, pets
                        and more.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="top-nav top-header sticky-header">
        <div class="container-fluid-lg">
          <div class="row">
            <div class="col-12">
              <div class="navbar-top">
                <button title="Toggle Sidebar"
                        class="navbar-toggler d-xl-none d-inline navbar-menu-button" type="button"
                        data-bs-toggle="offcanvas" data-bs-target="#primaryMenu">
                                <span class="navbar-toggler-icon">
                                    <i class="fa-solid fa-bars"></i>
                                </span>
                </button>
                <router-link to="/" class="web-logo nav-logo" title="Home">
                  <img src="/assets/images/logo/sokoni.webp" class="img-fluid blur-up lazyload"
                       alt="Sokoni Logo"
                       style="max-height: 60px">
                </router-link>
                <div class="middle-box">
                  <form class="search-box" action="/search">
                    <div class="input-group">
                      <input type="search" class="form-control" name="search"
                             placeholder="I'm looking for...? Type then hit enter to search..."
                             aria-label="Recipient's username" aria-describedby="button-addon2">
                      <button class="btn" type="submit" id="button-addon2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="feather feather-search">
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
                <div class="rightside-box">
                  <div class="search-full">
                    <div class="input-group">
                                        <span class="input-group-text">
                                            <i data-feather="search" class="font-light"></i>
                                        </span>
                      <input type="text" class="form-control search-type"
                             placeholder="Search here..">
                      <span class="input-group-text close-search">
                                            <i data-feather="x" class="font-light"></i>
                                        </span>
                    </div>
                  </div>
                  <div class="right-side-menu">
                    <div class="search-box">
                      <div class="input-group">
                        <router-link title="Search Listings" to="/search" class="btn" type="button" id="button-addon2">
                          Search <i class="fa-solid fa-search icon"></i>
                        </router-link>
                      </div>
                    </div>
                    <router-link title="Post Free Ad" to='/add-listing' class="btn btn-animation btn-sm mend-auto"
                                 data-bs-toggle="">
                      Start Selling
                      <i class="fa-solid fa-arrow-right icon"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid-lg">
        <div class="row">
          <div class="col-12">
            <div class="header-nav">
              <div class="header-nav-left">
                <button class="dropdown-category">
                  <i class="fa fa-list"></i> &nbsp;
                  <span>All Categories</span>
                </button>

                <div class="category-dropdown">
                  <div class="category-title">
                    <h5>Categories</h5>
                    <button type="button" class="btn p-0 close-button text-content">
                      <i class="fa-solid fa-list"></i>
                    </button>
                  </div>

                  <ul class="category-list">
                    <li class="onhover-category-list" v-for="category in categories ||{}">
                      <router-link :to="category?.link"
                                   class="category-name" :title="category?.name">
                        <img :src="category?.image_url" :alt="category?.page_title">
                        <h6>
                          {{ category?.name }}
                        </h6>
                        <i class="fa-solid fa-angle-right"></i>
                      </router-link>
                      <div class="onhover-category-box w-100">
                        <div class="list-1">
                          <ul>
                            <li v-for="sub_category in category?.sub_categories">
                              <router-link
                                :title="sub_category?.page_title"
                                :to="'/listings/categories/' + category?.slug + '/' + category?.id + '/' + sub_category?.slug + '/' + sub_category?.id">
                                {{ sub_category?.name }}
                              </router-link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="header-nav-middle">
                <div class="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                  <div class="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
                    <div class="offcanvas-header navbar-shadow">
                      <h5>Sokoni</h5>
                      <button class="btn-close lead" type="button" data-bs-dismiss="offcanvas"
                              aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                      <ul class="navbar-nav">
                        <li class="nav-item">
                          <router-link title="Home" class="nav-link" to="/">Home</router-link>
                        </li>

                        <li class="nav-item">
                          <router-link title="About Us" class="nav-link" to="/about">About Us</router-link>
                        </li>

                        <li class="nav-item">
                          <router-link title="Contact Us" class="nav-link" to="/contact">Contact Us</router-link>
                        </li>
                        <li class="nav-item">
                          <router-link title="FAQs" class="nav-link" to="/faq">FAQ</router-link>
                        </li>
                        <li class="nav-item">
                          <router-link title="Login" class="nav-link" to="/auth">Login</router-link>
                        </li>
                        <li class="nav-item">
                          <router-link title="Register" class="nav-link" to="/register">Sign Up</router-link>
                        </li>
                        <li class="nav-item">
                          <router-link title="Blog" class="nav-link" to="/blog/best-classifieds-in-kenya">
                            Blog
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="theme-option">
        <a href="https://play.google.com/store/apps/details?id=ke.sokoni.app"
           class="wow wow"
           target="_blank">
          <button class="btn-outline-info rounded" title="Download App">
            <span class="fa fa-android text-primary"></span>
          </button>
        </a>
        <div class="back-to-top">
          <a id="back-to-top" href="#" title="Scroll to Top">
            <i class="fas fa-chevron-up"></i>
          </a>
        </div>
      </div>
    </header>
  </div>

</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HeaderComponent',
  data() {
    return {
      mobile_menu: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['profile/loggedIn']
    },
    ...mapGetters({
      'profile': 'profile/user',
      'categories': 'categories/categories'
    })
  },
  methods: {
    ...mapActions({
      'profile': 'profile/getProfile',
      'getCategories': 'categories/getCategories'
    }),
    setTrackingInformation() {
      let url = new URL(window.location.href)
      let utm = (url.searchParams.get('utm'))
      if (utm !== null) {
        localStorage.setItem('source_utm', utm)
      }
    },
    toggleMobileMenu() {
      this.mobile_menu = !this.mobile_menu
      let menu = this.$refs.main_menu
      let width = window.innerWidth
      if (menu !== undefined) {
        if (width < 1071) {

          // this.$refs.menu_hide_mobile.classList.add('d-none')
          // this.$refs.menu_show_mobile.classList.remove('d-none')
          // this.$refs.menu_show_mobile.classList.add('d-block')

          if (this.$refs.main_menu.classList.contains('vismobmenu')) {
            this.$refs.main_menu.classList.remove('vismobmenu')
          } else {
            this.$refs.main_menu.classList.add('vismobmenu')
            this.$refs.main_menu.classList.remove('nav-holder')
          }
        } else {

          // this.$refs.menu_hide_mobile.classList.add('d-block')
          // this.$refs.menu_hide_mobile.classList.remove('d-none')
          // this.$refs.menu_show_mobile.classList.add('d-none')

          if (!this.$refs.main_menu.classList.contains('vismobmenu')) {
            this.$refs.main_menu.classList.add('nav-holder')
            this.$refs.main_menu.classList.remove('vismobmenu')
          } else {
            this.$refs.main_menu.classList.remove('nav-holder')
            this.$refs.main_menu.classList.add('vismobmenu')
          }
        }
      }

    }
  },
  created() {
    this.toggleMobileMenu()
    this.getCategories({})
    this.setTrackingInformation()
  }
}
</script>
