import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GeneralController',
  data() {
    return {
      sort_value: 'Most Recent'
    }
  },
  computed: {
    ...mapGetters({
      'permissions': 'users/user_permissions'
    })
  },
  methods: {
    resetMetaTags() {
      // Update Open Graph meta tags
      this.setMetaTag('og:title', 'Free classifieds in Kenya 🛍️ Post free classified ads.')
      this.setMetaTag('og:description', 'Free Classifieds in Kenya. Sell online for free at sokoni.ke 🛍️ Post Ad in Kenya 🛍️ local classified ads. Best deals in your area. Free business website.')
      this.setMetaTag('og:image', 'https://sokoni.ke/assets/images/logo/sokoni.webp')
      this.setMetaTag('og:url', 'https://sokoni.ke')

      this.setMetaTag('twitter:card', 'summary_large_image')
      this.setMetaTag('twitter:title', 'Free classifieds in Kenya 🛍️ Post free classified ads.')
      this.setMetaTag('twitter:description', 'Free Classifieds in Kenya. Sell online for free at sokoni.ke 🛍️ Post Ad in Kenya 🛍️ local classified ads. Best deals in your area. Free business website.')
      this.setMetaTag('twitter:image', 'https://sokoni.ke/assets/images/logo/sokoni.webp')
      this.setMetaTag('twitter:url', 'https://sokoni.ke')
    },
    setMetaTag(name, content) {
      let element = document.querySelector(`meta[name="${name}"], meta[property="${name}"]`)
      if (element) {
        element.setAttribute('content', content)
      } else {
        element = document.createElement('meta')
        if (name.startsWith('og:') || name.startsWith('twitter:')) {
          element.setAttribute('property', name)
        } else {
          element.setAttribute('name', name)
        }
        element.setAttribute('content', content)
        document.head.appendChild(element)
      }
    },
    stripHTML(html) {
      let doc = new DOMParser().parseFromString(html, 'text/html')
      return doc.body.textContent || ''
    },
    ...mapActions({
      'getPermissions': 'users/getUserPermissions'
    }),
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    can(permission) {
      return (Array.from(this.permissions)?.includes(permission))
    },
    formatNumber(number) {
      return number === undefined || number === 0 ? '0' : number.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .replace('.00', '')
    },
    formatDate(date) {
      return new Date(date).toDateString()
    },
    handleSortCommand(command) {
      this.filters.sort_by = command
      if (command === 'price-desc') {
        this.sort_value = 'Price - Highest to Lowest'
      } else if (command === 'price-asc') {
        this.sort_value = 'Price - Lowest to Highest'
      } else if (command === 'date-asc') {
        this.sort_value = 'Least Recent'
      } else if (command === 'date-desc') {
        this.sort_value = 'Most Recent'
      }
      this.getListings(this.filters)
    }
  }
}
