import api from '../api'
import { ElMessage } from 'element-plus'
import router from '@/routes'

export default {
    namespaced: true,
    state: {
        businesses: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
        loading: false,
        business: {}
    },
    getters: {
        businesses: state => state.businesses,
        business: state => state.business,
        errors: state => state.errors,
        loading: state => state.loading,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setBusinesses(state, data) {
            state.businesses = data;
        },
        setBusiness(state, data) {
            state.business = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        setLoading(state, val) {
            state.loading = val
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getBusinesses({commit}, filters) {
            try {
                commit('activateLoading', 'user/list', {root: true});
                const response = await api.getBusinesses(filters);
                commit('setBusinesses', response);
                commit('deactivateLoading', 'business/list', {root: true});
            } catch (e) {
                ElMessage.error(e.response.data.message);
            }
        },
        async getBusiness({commit}, filters) {
            try {
                commit('activateLoading', 'user/list', {root: true});
                const response = await api.getBusiness(filters);
                commit('setBusiness', response?.data);
                commit('deactivateLoading', 'business/list', {root: true});
            } catch (e) {
                ElMessage.error(e.response.data.message);
            }
        },

        async registerBusiness({dispatch, commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                const response = await api.registerBusiness(data);
                ElMessage.success('Business created successfully!');
                commit('setLoading', false)
                await router.replace('/business/pay/' + response?.data?.id + '/' + response?.package_id)
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage.error({
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },

        async updateBusiness({dispatch, commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.editBusiness(data);
                ElMessage.success('Business updated successfully!');
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage.error({
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },

        async removeBusinessTag({dispatch, commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.removeBusinessTag(data);
                ElMessage.success('Tag removed successfully!');
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage.error({
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
    }
}
