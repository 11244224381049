import { createRouter, createWebHistory } from 'vue-router'
import auth from './auth.routes'
import profile from './profile.routes'
import pages from './pages.routes'
import authMiddleware from './middlewares/auth-middleware'

// Vue.use(Router);

const router = new createRouter({
  history: createWebHistory(),
  routes: [
    ...auth,
    ...profile,
    ...pages
    // {
    //     path: '*',
    //     redirect: '/dashboard',
    // },
  ]
})

router.beforeEach((to, from, next) => {
  let result
  if (to.meta.auth) {
    result = authMiddleware(to, next)
  } else {
    result = next()
  }
  return result
})

router.beforeEach((to, from, next) => {
  const canonical = `https://sokoni.ke${to.fullPath}`

  let link = document.querySelector('link[rel="canonical"]')
  if (link) {
    link.href = canonical
  } else {
    link = document.createElement('link')
    link.rel = 'canonical'
    link.href = canonical
    document.head.appendChild(link)
  }
  next()
})


export default router
