import client from './client'

export default {
    async getBusinesses(filters) {
        return client.parseResponse(await client.get('/businesses', filters));
    },

    async registerBusiness(data) {
        return client.parseResponse(await client.post('/register-business', data))
    },
    async removeBusinessTag(data) {
        return client.parseResponse(await client.post('/remove-business-tag', data))
    },

    async getBusiness(id) {
        return client.parseResponse(await client.get('/businesses/' + id));
    },

    async editBusiness(data) {
        return client.parseResponse(await client.put('/businesses/' + data.id, data))
    }
}
