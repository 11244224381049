import store from '../../data/store'

const aclMiddleware = (to, next) => {
    setTimeout(() => {
        const loggedIn = store.getters['profile/loggedIn'];
        const user = store.getters['profile/user'];
        if (!loggedIn) {
            window.location.href = '/login';
            return next(false);
        }
        if (loggedIn) {
            // console.log(user);
            if (to.meta.auth && user.modules && user.modules.filter(i => i.module.key === to.meta.parent_key) < 1) {
                window.location.href = '/access-denied';
                return next(false);
            }
        }
        return next();
    }, 1000)
};

export default aclMiddleware;
