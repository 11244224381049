import api from '../api'
import { ElMessage } from 'element-plus'

export default {
  namespaced: true,
  state: {
    packages: { data: [], current_page: 1, total: 0, per_page: 10 },
    errors: {
      data: {
        errors: {
          name: []
        }
      }
    },
    has_errors: false
  },
  getters: {
    packages: state => state.packages,
    errors: state => state.errors,
    has_errors: state => state.has_errors
  },
  mutations: {
    setPackages(state, data) {
      state.packages = data
    },
    setErrors(state, errors) {
      state.errors = errors
    },
    hasErrors(state, val) {
      state.has_errors = val
      if (!val) {
        state.errors = {
          data: {
            errors: {
              name: []
            }
          }
        }
      }
    }
  },
  actions: {
    async getPackages({ commit }, filters) {
      try {
        commit('activateLoading', 'user/list', { root: true })
        const response = await api.getPackages(filters)
        commit('setPackages', response)
        commit('deactivateLoading', 'package/list', { root: true })
      } catch (e) {
        ElMessage.error(e.response.data.message)
      }
    },
    async getBusinessPackages({ commit }, filters) {
      try {
        commit('activateLoading', 'user/list', { root: true })
        const response = await api.getBusinessPackages(filters)
        commit('setPackages', response)
        commit('deactivateLoading', 'package/list', { root: true })
      } catch (e) {
        ElMessage.error(e.response.data.message)
      }
    }

  }
}
