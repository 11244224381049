import api from '../api'
import { ElMessage } from 'element-plus'

export default {
  namespaced: true,
  state: {
    categories: [],
    extra_field_values: [],
    fields: {},
    category_filters: {},
    errors: {
      data: {
        errors: {}
      }
    },
    has_errors: false,
    loading: false,
    loading_filters: false,
    dialogVisible: false
  },
  getters: {
    dialogVisible: state => state.dialogVisible,
    loading: state => state.loading,
    categories: state => state.categories,
    fields: state => state.fields,
    category_filters: state => state.category_filters,
    loading_filters: state => state.loading_filters,
    errors: state => state.errors,
    has_errors: state => state.has_errors,
    extra_field_values: state => state.extra_field_values
  },
  mutations: {
    setLoading(state, data) {
      state.loading = data
    },
    setFiltersLoading(state, data) {
      state.loading_filters = data
    },
    setCategories(state, data) {
      state.categories = data
    },
    setFields(state, data) {
      state.fields = data
    },
    setCategoryFilters(state, data) {
      state.category_filters = data
    },
    setExtraFieldValues(state, data) {
      state.extra_field_values['item_' + data[0]] = data[1]
    },
  },
  actions: {

    async getFields({ commit }, filters) {
      commit('setLoading', true)
      try {
        const response = await api.getFields(filters)
        commit('setFields', response)
        commit('setLoading', false)
      } catch (e) {
        ElMessage.error(e.response.data.message)
        commit('setLoading', false)
      }
    },
    async getCategories({ commit }, filters) {
      commit('setLoading', true)
      try {
        const response = await api.getCategories(filters)
        commit('setCategories', response)
        commit('setLoading', false)
      } catch (e) {
        ElMessage.error(e.response.data.message)
        commit('setLoading', false)
      }
    },

    async getCategoryFilters({ commit }, filters) {
      commit('setFiltersLoading', true)
      try {
        const response = await api.getCategoryFilters(filters)
        commit('setCategoryFilters', response)
        commit('setFiltersLoading', false)
      } catch (e) {
        ElMessage.error(e.response.data.message)
        commit('setFiltersLoading', false)
      }
    },
    async getFieldsValues({ commit }, filters) {
      commit('setLoading', true)
      commit('setExtraFieldValues', [filters.field_id, []])
      try {
        const response = await api.getFieldValues(filters)
        commit('setExtraFieldValues', [filters.field_id, response])
      } catch (e) {
        ElMessage.error(e.response.data.message)
        commit('setLoading', false)
      }
    },
  }
}
